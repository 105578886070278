import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/ArticleLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`A broadcast are streaming that you do using  CoreMedia Digital Experience Platform announcing new products or services to your customers.`}<br /><br />{`Brodcasts have a list of contacts associated. Broadcats Contacts panels list information and metrics associated to the the contacts that were added to the broadcast.`}</p>
    <p>{`The following tables list the data fields of Broadcast Contacts listing panel, sorted by alphabetic order. Use `}<em parentName="p">{`CRTL + F`}</em>{` in Windows or `}<em parentName="p">{`Command + F`}</em>{` in MacOS to search inside the page.`}</p>
    <h2>{`Broadcast Information`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Access time`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Time between the time of the contact request to access the broadcast and the time that the access is granted.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Bounce time`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Amount of time that the visitor spends on the broadcast.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Broadcast ID`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique identifier of the broadcast. For example, `}<inlineCode parentName="td">{`12345-Father's day promotion`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Like`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`One of the following:`}<ul><li><strong parentName="td">{`Yes`}</strong>{`: The contact liked the broadcast.`}</li><li><strong parentName="td">{`No`}</strong>{`: The contact didn't like the broadcast.`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Messages`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Visitor comments to the streamed video. For example, `}<inlineCode parentName="td">{`I both the product and I am extremelly satisfied witbh it. It's a must have.`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`NPS Mood`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Mood of the visitor that CoreMedia Experience Platform detected in the interaction.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`NPS Score`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Net Promoter Score (NPS) value, typically, is a value between 0 and 10, where 0 means that the customer is not happy and will not recommend the business to anyone, and 10 means that the customer is very happy and will recommend the business to everyone.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Rating`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Rating that the customer gives to the streamed video. Values between `}<inlineCode parentName="td">{`1`}</inlineCode>{` and `}<inlineCode parentName="td">{`5`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Streaming date`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date and time of the Streaming in format YYYY-MM-DD hh:mm:ss. For example, `}<inlineCode parentName="td">{`2025-03-19 12:00:03`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Streaming name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the streaming.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Visits`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Number of visits during the broadcast.`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Campaign Information`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Abandonment element`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`For `}<strong parentName="td">{`onsite campaigns`}</strong>{`, name of the page element that contains the window from which the customer that visited the site abandoned the contact.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Active element`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`For `}<strong parentName="td">{`onsite campaigns`}</strong>{`, name of the active element that contains the window from which the customer requested the contact.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Campaign tags`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`List of tags associated with the campaign.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`External Campaign ID`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If it exists, identifier of external campaign from which the customer requested the contact.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`External campaign`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If it exists, name of external campaign from which the customer requested the contact.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`External campaign type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If it exists, type of external campaign from which the customer requested the contact. For example, a Facebook campaign.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`External campaigns`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`List of the names of the external campaigns that handled the customer until the customer requested the contact.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`External campaigns IDs`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`List of the identifiers of the external campaigns that handled the customer until the customer requested the contact.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Onsite campaign`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the onsite campaign that contains the window from which the customer requested the contact.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Onsite campaign ID`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Identifier of the onsite campaign that contains the window from which the customer requested the contact.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Outbound campaign`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`For `}<strong parentName="td">{`outbound campaigns`}</strong>{`, name of the outbound voice campaign where Engagement Cloud created the contact.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Placeholder`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Identifier of the placeholder that contains the window from which the customer requested the contact.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Widget`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the component of the onsite campaign from which the customer requested the contact.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Widget tags`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`List of tags associated with the component of the onsite campaign from which the customer requested the contact.`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Contact Information`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact total duration (by intervals)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Total duration of the contact represented as an interval. For example, `}<inlineCode parentName="td">{`16-30`}</inlineCode>{` minutes.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Customer review`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Customer's evaluation of the operator who handled the contact.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration (by intervals)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact duration in represented in an interval. For example, `}<inlineCode parentName="td">{`31-45`}</inlineCode>{` minutes.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Form information`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Information retrieved from the contact form.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Wait time (until contact center) (by intervals)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Time that the con`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Waiting time already in contact (by intervals)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Time that the customer waited while in queue. For example, while waiting for the operator after IVR.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Waiting time in queue (by intervals)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Time that the customer waited while in queue represented in intervals.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Waiting time until contacted (by intervals)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Time that the customer waited after requested to be contacted represented in intervals.`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Contact Information > Contact Finalization`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Credits`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Number of credits attributed to the operator that handled the contact.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Disconnection origin`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`For chat interactions, entity responsible for ending the communication. One of the following:`}<ul><li>{`Operator`}</li><li>{`Customer`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Hangup cause`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Cause of hangup for chat interactions with the customer. One of the following:`}<ul><li>{`Visitor disconnection`}</li><li>{`Ended by operator`}</li><li>{`Visitor inactivity`}</li><li>{`Ended by visitor`}</li></ul>{`Note that each client can add more hangup causes.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Hangup cause (contact center)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Cause of hangup for voice interactions with the contact center. One of the following:`}<ul><li>{`Completed transfer`}</li><li>{`Failed transfer`}</li><li>{`Canceled transfer`}</li><li>{`Busy`}</li></ul>{`Note that each client can add more hangup causes.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Has conversion?`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`One of the following:`}<ul><li>{`Yes - If the contact ends with a conversion.`}</li><li>{`No - If the contact doesn't end with a conversion.`}</li></ul>{`Note that the definition of conversion depends on the campaign purpose. For example, the customer bought a product or the customer filled in a survey giving information to the campaign.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Is it classified?`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`One of the following:`}<ul><li>{`Yes - Engagement Cloud automatically classified the contact or the operator manually classified the contact.`}</li><li>{`No - Engagement Cloud failed to classify the contact or there is no classification available.`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Wrapup code`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Code that the client defined to wrapup the contact. For example, `}<inlineCode parentName="td">{`Sale`}</inlineCode>{`.`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Contact Information > Dates And Times`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact date (YYYYMMDD)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date when the contact started. For example, `}<inlineCode parentName="td">{`2025-01-15`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact duration (in operation)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`One of the following:`}<ul><li>{`If the customer calls the contact, this represents the time when the operator or the IVR answers the call until the call ends.`}</li><li>{`If the contact center calls a customer, this represents the time from when the customer answers the call until it ends.`}</li><li>{`If the `}<em parentName="td">{`Contact Type`}</em>{` = `}<inlineCode parentName="td">{`Chat`}</inlineCode>{`, this represents the time when the customer receives the first message until the chat ends due to one of the following reasons:`}<ul><li>{`The operator ended the chat.`}</li><li>{`The chat was inactive.`}</li><li>{`Lack of persistence.`}</li></ul></li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact total duration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The total duration of the contact from the time the interaction reaches the contact center until it finishes. For voice calls, it also includes time spend in the IVR.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Date and time of contact`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date and time when the contact started.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Date of contact request`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date when the customer requested the contact.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Date of initial scheduling`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date of the first scheduling to contact the customer.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Day of contact (DD)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Day when the contact started.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Hangup contact date`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`For voice interactions, date when the contact hung up and ended the interaction.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Hour of contact (HH)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Hour when the contact started.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Initial date of 1st attempt`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`For outbound contacts, date of the first attempt to contact the customer.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Initial date of success`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`For outbound contacts, date of the first attempt that the customer answered.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Wait time (until contact center)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Waiting time from the beginning of the contact until the customer reaches an operator.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Waiting time already in contact`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Time that the customer waited while on hold or, while waiting for information from the operator, or while waiting to reach an operator after an IVR transfer.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Waiting time in queue`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Waiting time while the contact is in queue.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Waiting time until contacted`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Time since the costumer requested the contact until the first attempt to contact the customer happened.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Week of contact`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Number of the week when the contact started`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Weekday of contact`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Number of the weekday when the contact started. One of the following:`}<ul><li>{`1 for Monday`}</li><li>{`2 for Tuesday`}</li><li>{`3 for Wednesday`}</li><li>{`4 for Thursday`}</li><li>{`5 for Friday`}</li><li>{`6 for Saturday`}</li><li>{`7 for Sunday`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Year and month of contact (YYYYMM)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Year and month when the contact started. For example, `}<inlineCode parentName="td">{`January 2025`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Year of contact (YYYY)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Year when the contact started. For example, `}<inlineCode parentName="td">{`2025`}</inlineCode>{`.`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Contact Information > General`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact channel`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Channel of the interaction with the customer. One of the following:`}<ul><li>{`Default`}</li><li>{`Voice`}</li><li>{`Video`}</li><li>{`Chat`}</li><li>{`Email`}</li><li>{`SMS`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact hangup origin`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Who ended the interaction. One of the following:`}<ul><li>{`Operator`}</li><li>{`Customer`}</li><li>{`System`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact language`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Preferred language for inter with the customer. For example, `}<inlineCode parentName="td">{`Portuguese`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact request IP`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`IP from which the customer requested the contact.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact request domain`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`URL from where the visitor requested the conctact.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact status`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`One of the following:`}<ul><li>{`Success`}</li><li>{`Un-success`}</li></ul>{`Note that the `}<em parentName="td">{`Status per contact type`}</em>{` field contains details about each contact status.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`One of the following:`}<ul><li><strong parentName="td">{`Voice`}</strong>{`: Contact where the customer talks with an operator through a voice call. The voice call can be inbound, outbound, or Click2Call.`}</li><li><strong parentName="td">{`Video Broadcast`}</strong>{`: Voice calls that include video. `}</li><li><strong parentName="td">{`Chat`}</strong>{`: Contacts where customers use messaging to communicate with the contact center.`}</li><li><strong parentName="td">{`Forms`}</strong>{`: Voice, video, or chat contacts that the customer started using a form.`}</li><li><strong parentName="td">{`External`}</strong>{`: Contacts where the operator talks with the customer outside the CoreMedia Contact Center. After finishing, the operator creates the contact manually in the CoreMedia Contact Center.`}</li></ul>{`Note that the `}<em parentName="td">{`Contact Subtype`}</em>{` field contains details about each contact type.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Coremedia CID`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact unique identifier according to the contact type. For example, a voice contact can have CID = `}<inlineCode parentName="td">{`154242`}</inlineCode>{` and a chat contact can also have CID = `}<inlineCode parentName="td">{`154242`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Destination`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Destination of the interaction. For example, `}<inlineCode parentName="td">{`Click2Call`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Operator ID`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique identifier of the operator that handled the interaction with the customer.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Operator name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the operator that handled the interaction with the customer.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Operator tags`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Tags associated to the operator that handle the interaction.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Organization`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the company that provides the Internet service to the visitor. For example, `}<inlineCode parentName="td">{`Vodafone`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Phone`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Phone number of the contact.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Skill`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the skill of the operator or IVR that handled the contact.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Status per contact type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`One of the following:`}<ul><li>{` If `}<em parentName="td">{`Contact Type`}</em>{` = `}<inlineCode parentName="td">{`Success`}</inlineCode>{`, one of the following:`}<ul><li><strong parentName="td">{`Normal`}</strong>{`:  Every contact from the customer that reaches an operator.`}</li><li><strong parentName="td">{`Finished on IVR`}</strong>{`: Contacts where the customer is able to solve the problem or complete the intended task using just the IVR. For example, a call to the electric company to communicate monthly consumptions.`}</li><li><strong parentName="td">{`Template sent`}</strong>{`: Contacts that end with a template sent to WhatsApp.`}</li></ul></li><li>{`If `}<em parentName="td">{`Contact Type`}</em>{` = `}<inlineCode parentName="td">{`Un-Success`}</inlineCode>{`, one of the following:`}<ul><li>{`Contact Center did not answer`}</li><li>{`Customer did answer, but the Contact Center didn't answer`}</li><li>{`Abandonment in queue`}</li><li>{`Scheduled contact`}</li><li>{`Customer did not answer`}</li><li>{`Disconnected on IVR`}</li><li>{`Contact Center didn't answer after IVR success`}</li><li>{`Impossible to sent`}</li></ul></li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Team`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Team of the operator that handled the interaction.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice contact subtype`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`For voice interactions, one of the following:`}<ul><li>{`Inbound`}</li><li>{`Outbound`}</li><li>{`API`}</li><li>{`Calltracker`}</li><li>{`Click2Call`}</li></ul></td>
        </tr>
      </tbody>
    </table>
    <h2>{`Contact Information > Location Information`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Country`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Country of the customer. For example, `}<inlineCode parentName="td">{`Portugal`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Internet provider`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the Internet provider of the customer. For example, `}<inlineCode parentName="td">{`Vodafone Portugal`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Region`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the city of the customer. For example, `}<inlineCode parentName="td">{`Lisbon`}</inlineCode>{`.`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Contact Information > Other Services`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Has recording?`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`One of the following:`}<ul><li><strong parentName="td">{`Yes`}</strong>{`: The interaction of the contact was recorded.`}</li><li><strong parentName="td">{`No`}</strong>{`: The interaction of the contact was not recorded.`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Human verified`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`One of the following:`}<ul><li><strong parentName="td">{`Yes`}</strong>{`: The contact classification was manually verified.`}</li><li><strong parentName="td">{`No`}</strong>{`: The contact classification was not verified manually.`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Recording`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the recording.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Transcript`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the transcript.`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Contact Information > Specific By Contact Type`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact Center number`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Number of the contact center that the customer used. For example, `}<inlineCode parentName="td">{`351211212121`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Inbound number`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Number that the customer used to call the contact center. For example, `}<inlineCode parentName="td">{`351221234567`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Number of attempts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If `}<em parentName="td">{`Contact Subtype`}</em>{` is `}<inlineCode parentName="td">{`API`}</inlineCode>{` or `}<inlineCode parentName="td">{`Click2Call`}</inlineCode>{`, contains the number of call attempts that the contact center made until the customer answers or until it reaches the maximum number of attempts.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Operator identification method`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`One of the following:`}<ul><li>{`API (A)`}</li><li>{`DTMF (D)`}</li><li>{`Console (T)`}</li><li>{`System (S)`}</li><li>{`Transfer (F)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Virtual inbound number`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Virtual phone number that the customer used to call the contact center. Typically, mobile phone numbers.`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Customer Information > Customer Identification`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Cookie`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ID of the cookie of the visitor.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Coremedia VID`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Visitor unique identifier.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Identification type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Type of visitor identification. For example, `}<inlineCode parentName="td">{`2031253224`}</inlineCode>{`.`}<br />{`One of the following:`}<ul><li><strong parentName="td">{`FId`}</strong>{`: The Digital Experience Platform generated the visitor identifier.`}</li><li><strong parentName="td">{`Cookie ID`}</strong>{`: Digital Experience Platform read the visitor ID from a Cookies.`}</li></ul></td>
        </tr>
      </tbody>
    </table>
    <h2>{`Customer Information > Segments`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Segment IDs`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Identifiers of the segments associated to the visitor. For example, `}<inlineCode parentName="td">{`A1B345C6DEFGH789I0JK`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Segments`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the segments associated to the visitor. For example, `}<inlineCode parentName="td">{`January Promo`}</inlineCode>{`.`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Session Information > Device Information`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Browser`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Browser that the using is using. For example, `}<inlineCode parentName="td">{`Chrome Android`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Browser resolution`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Graphical resolution of the browser in pixels. For example, `}<inlineCode parentName="td">{`1536x864`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Device family`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Brand family of the device that the customer used to access the website. For example, `}<inlineCode parentName="td">{`Galaxy A12`}</inlineCode>{`, `}<inlineCode parentName="td">{`IPhone`}</inlineCode>{`, or `}<inlineCode parentName="td">{`Macintosh`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Device model`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Model of the device that the customer used to access the website. For example, `}<inlineCode parentName="td">{`SM-A125f`}</inlineCode>{`, `}<inlineCode parentName="td">{`Iphone 11`}</inlineCode>{`, or `}<inlineCode parentName="td">{`MacBook Pro`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Device operating system`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`operating system of the device that the customer used to access the website. For example, `}<inlineCode parentName="td">{`Android`}</inlineCode>{`, `}<inlineCode parentName="td">{`Windows`}</inlineCode>{`, or  `}<inlineCode parentName="td">{`IPhone Osx`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Device type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Type of device. One of the following:`}<ul><li>{`Smartphone`}</li><li>{`Tablet`}</li><li>{`Desktop`}</li><li>{`Console`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Device vendor`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Vendor of the device that the customer used to access the website. For example, `}<inlineCode parentName="td">{`Samsung`}</inlineCode>{`, `}<inlineCode parentName="td">{`Apple`}</inlineCode>{`, or `}<inlineCode parentName="td">{`HP`}</inlineCode>{`.`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Session Information > General`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`FID (external ID)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`External identifier of the visitor.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Referrer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Source or link that referred the visitor.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Referrer domain`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Domain  of the page that contains the link that the visitor used to open the page. For example, `}<inlineCode parentName="td">{`https://www.google.com`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Search terms`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Sequence of terms that the visitor used in the last search. For example, `}<inlineCode parentName="td">{`kitchen knive`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Session IP`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`IP of the session.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Simplified URL`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Simplified URL of the page that the visitor viewed. For example, `}<inlineCode parentName="td">{`https://documentation.coremedia.com/engagement-cloud/analytics`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Simplified referrer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Simplified URL that contains the link to the page that the visitor viewed. For example, `}<inlineCode parentName="td">{`https://documentation.coremedia.com/engagement-cloud/analytics`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`URL address`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`URL address that the customer visited to reach the contact center.`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      